<template>
  <div class="">
    <CostsList />
  </div>
</template>

<script>
import CostsList from "@/components/lists/CostsList.vue";

export default {
  components: {
    CostsList,
  },
};
</script>